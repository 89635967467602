import React, {useEffect, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import Box from "../components/box";
import RadioBox from "../components/radioBox";
import Badge from "react-bootstrap/Badge";
import {
    dotsRight,
    folkRadio,
    playButton,
    podcastCover,
    radioKielce,
    soundWaves,
    banner,
    bannerDesktop
} from "../images";
import {fetchAll, fetchCategory, getFeatured, getPodcastById} from "../api/podcastsAPI";
import PopularBox from "../components/popularBox";
import Loader from "react-loader-spinner";
import {Link} from "react-router-dom";
import Autocomplete from "../components/autocomplete";
import {setCategoryName, setPlayer} from "../actions";
import {connect} from "react-redux";
import {player} from "../reducers/player";
import Dotdotdot from 'react-dotdotdot'


const compareCategory = (a, b) => {
    if (a.name_pl < b.name_pl) {
        return -1;
    }
    if (a.name_pl > b.name_pl) {
        return 1;
    }
    return 0;
};


const getDifferentTime = (time) => {

    let episode_at = new Date(time).getTime();
    let actually_date = new Date().getTime();

    const diffTime = Math.abs(actually_date - episode_at);


    return Math.ceil(diffTime / (1000 * 60 * 60 * 24))

};


const Home = (props) => {

    if (props.player.play) {
        document.title = `▶ ${props.player.podcastTitle} - Podcasty Radia Kielce`;
    } else {
        document.title = `Podcasty Radia Kielce`;
    }


    const displayPodcastsByCategory = () => {

        let listByCategory = [];

        allPodcasts.forEach(item => {
            if (item.category === props.categories.categoryName) {
                listByCategory.push(item);
            }
        });


        if (props.categories.categoryName) {
            return listByCategory;
        } else {
            return podcasts
        }

    };


    const [tags, setTags] = useState([]);

    const [loading, setLoading] = useState(true);

    const [podcasts, setPodcasts] = useState([]);
    const [allPodcasts, setAllPodcasts] = useState([]);
    const [epizodes, setEpizodes] = useState([]);
    const [featured, setFeatured] = useState(null);


    const getCategory = () => {
        fetchCategory()
            .then(response => {
                if (response) {

                    response.sort(compareCategory);

                    setTags(response)
                }
            })
            .catch(err => console.log(err))
    };

    const getRecommend = () => {
        getFeatured()
            .then(response => {
                if (response) {
                    setFeatured(response)
                }
            })
            .catch(err => console.log(err))
    };

    const getAllPodcasts = () => {
        fetchAll()
            .then(response => {
                if (response) {
                    setAllPodcasts([...response]);
                    setPodcasts(response);
                }
                setLoading(false)
            })
            .catch(() => {
                setLoading(false)
            })

        setTimeout(getAllPodcasts, 180000)

    };

    const getPodcast = (id) => {
        getPodcastById(id)
            .then(response => {
                if (response) {
                    Array.isArray(response) ? setEpizodes(response) : setEpizodes([])
                }
            })
            .catch(err => {
                console.log(err);
            })
    };


    useEffect(() => {
        getRecommend();
        getCategory();
        getAllPodcasts();
        getPodcast(4090756);
    }, []);


    useEffect(() => {

        if (props.player.play) {
            document.title = `▶ ${props.player.podcastTitle} - Podcasty Radia Kielce`
        } else {
            document.title = `${props.player.podcastTitle} - Podcasty Radia Kielce`
        }

    }, [props.player.play]);


    const openRadio = (radio) => {

        if (radio === 'Radio Kielce') {
            props.setPlayer({
                ...player,
                source: 'https://streaming.g-news.pl:8030/rk2',
                id: 'radiokielce',
                title: 'Radio Kielce',
                podcastTitle: 'Radio Kielce',
                play: true
            });


        } else {
            props.setPlayer({
                ...player,
                source: 'https://streaming.g-news.pl:8030/fr1',
                id: 'folkradio',
                title: 'Folk Radio',
                podcastTitle: 'Folk Radio',
                play: true
            });


        }


    };


    const selectCategory = (item) => {


        if (props.categories.categoryName === item.name_pl) {
            props.setCategory({
                categoryName: ''
            });
        } else {
            props.setCategory({
                categoryName: item.name_pl
            });
        }


    };


    return (
        <React.Fragment>
            <Container fluid style={{maxWidth: '1410px', position: 'relative', marginTop: '56px'}}>
                <Row>
                    <Col xl={8}>
                        <div className='mt-3 mt-lg-5'>
                            <h1 className='title'>Podcasty <span className="font-weight-normal">Radia Kielce</span>
                            </h1>
                        </div>
                        <Row className='mt-2 mt-lg-5'>
                            <Col md={6}>
                                {podcasts && <Autocomplete podcasts={podcasts}/>}

                                <p className='mt-3' style={styles.subTitle}>
                                    Historia, zdrowie, moda, podróże, publicystyka… Audycji Radia Kielce można słuchać
                                    w dowolnym momencie w formie podcastów - to „radio na życzenie”, którego słuchasz
                                    kiedy chcesz.
                                </p>
                            </Col>
                            <Col md={6} className='d-none d-md-block'>

                                {tags && tags.map((item, index) => <Badge
                                    key={index}
                                    style={props.categories.categoryName === item.name_pl ? {backgroundColor: '#f1b0b7'} : null}
                                    className={'badgePink'}
                                    onClick={() => selectCategory(item)}
                                >{item.name_pl}</Badge>)}

                            </Col>
                        </Row>


                        {!props.categories.categoryName ? <PopularBox visibleClass={''} xl={3} count={4}/> :

                            <Row>

                                <Col xs={12}>
                                    <h3 className='popularTitle'>{props.categories.categoryName}</h3>
                                </Col>

                                {displayPodcastsByCategory().map((item, index) =>

                                    <Col xs={6} sm={4} md={3} xl={3} key={index} className='mt-15'>

                                        <Box title={item.title}
                                             episodes={item.episodes}
                                             isNew={item.isNew}
                                             imageUrl={item.image_original_url}
                                             id={item.friendly_url}
                                        />
                                    </Col>
                                )}
                            </Row>

                        }

                    </Col>
                    <Col xl={4} className='position-relative d-none d-xl-block'>
                        <div style={styles.dotsPosition}/>
                        <div style={{
                            backgroundImage: `url(${featured ? featured.cover_image_url : null})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'right',
                            minHeight: '500px',
                            position: 'relative',
                            maxWidth: '400px'
                        }}>

                            <p style={styles.rightText}>
                                <span style={{width: '30px', borderBottom: '1px solid #fff', marginRight: '10px'}}/>
                                <span style={{position: 'relative', top: '10px'}}>Polecamy</span>
                            </p>

                            <div style={styles.positionTextRight}>

                                <img src={soundWaves} alt="sounds waves" className='wavesPosition'/>

                                {featured && <Link to={"/" + featured.show_friendly_url}>
                                    <img className='playButton-position' src={playButton} alt="play button"/>
                                </Link>}

                                <h3 className='rightPanelTitle'>{featured && featured.title}</h3>

                                {featured ? <Dotdotdot className='rightPanelDesc' clamp={7}>
                                    {featured.description}
                                </Dotdotdot> : null}

                                <div className='d-block mt-4'>
                                    <img className='rightPanelImg mr-3 float-left'
                                         src={featured ? featured.image_url : null}
                                         alt=""/>
                                    <div>
                                        <p className='m-0'
                                           style={styles.rightTextIcon1}>{featured && featured.show_title}</p>
                                        <p className='m-0'
                                           style={styles.rightTextIcon2}>Dodano {featured && getDifferentTime(featured.published_at)} dni
                                            temu</p>
                                    </div>
                                </div>
                            </div>


                        </div>


                    </Col>
                </Row>


                <Row style={props.categories.categoryName ? {paddingTop: '70px'} : null} className='d-flex flex-row'>


                    {!props.categories.categoryName ? <>
                            <Col className='mt-15' xs={6} sm={4} md={3} xl={2} onClick={() => openRadio('Radio Kielce')}>
                                <RadioBox title='Radio Kielce'
                                          imageUrl={radioKielce}/>
                            </Col>
                            <Col className='mt-15' xs={6} sm={4} md={3} xl={2} onClick={() => openRadio('Folk Radio')}>
                                <RadioBox title='Folk Radio'
                                          imageUrl={folkRadio}
                                />
                            </Col> </>


                        : null}

                    {loading ?

                        <Col xs={12} sm={4} xl={8} className='d-flex justify-content-center align-items-center'
                             style={{height: '300px'}}>
                            <Loader
                                type="Oval"
                                color="#000"
                                height={100}
                                width={100}

                            />
                        </Col> :

                        !props.categories.categoryName ? displayPodcastsByCategory().map((item, index) =>

                            <Col xs={6} sm={4} md={3} xl={2} key={index} className='mt-15'>

                                <Box title={item.title}
                                     episodes={item.episodes}
                                     isNew={item.isNew}
                                     imageUrl={item.image_original_url}
                                     id={item.friendly_url}
                                />
                            </Col>
                        ) : null}

                </Row>
                <Row>
                    <a href="https://wielkaliteratura.online/" target="_blank" className="w-100 mt-4">
                        <picture >
                            <source
                              media="(min-width: 768px)"
                              srcSet={bannerDesktop} />
                            <source
                              media="(min-width: 576px)"
                              srcSet={banner} />
                            <img className="col-md-12 w-100" src={banner} alt="Wielka literatura w cyfrowym świecie" />
                        </picture>
                    </a>

                </Row>
            </Container>
        </React.Fragment>
    )

}


const styles = {
    subTitle: {
        fontSize: '14px',
        lineHeight: '18px',
        color: '#5C5C5C',
        textAlign: 'left',
        maxWidth: '390px'
    },
    sectionTitle: {
        fontWeight: 'bold',
        fontSize: '20px',
        color: '#252D44',
        textTransform: 'uppercase',
        marginBottom: '20px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '100%',
    },
    positionTextRight: {
        position: 'absolute',
        bottom: '-90px',
        backgroundColor: '#fff',
        padding: '30px 10px',
        width: '80%'
    },
    rightText: {
        position: 'absolute',
        top: '20px',
        color: '#fff',
        fontWeight: 'bold',
        fontSize: '14px',
        display: 'flex',
        margin: '0'
    },
    rightTextIcon1: {
        fontWeight: 'bold',
        fontSize: '14px',
        color: '#252D44'
    },
    rightTextIcon2: {
        fontWeight: 'normal',
        fontSize: '14px',
        color: 'rgba(37, 45, 68, 0.400595)'
    },
    dotsPosition: {
        backgroundImage: `url(${dotsRight})`,
        backgroundSize: 'cover',
        height: '140px',
        width: '140px',
        position: 'absolute',
        left: '-130px',
        top: '5px'
    }
};


const mapDispatchToProps = dispatch => ({
    setPlayer: player => dispatch(setPlayer(player)),
    setCategory: categories => dispatch(setCategoryName(categories)),
});

const mapStateToProps = state => ({
    player: state.player,
    categories: state.category
});

export default connect(mapStateToProps, mapDispatchToProps)(Home)
