import React from 'react';
import Card from "react-bootstrap/Card";
import Badge from "react-bootstrap/Badge";
import { Link } from "react-router-dom";
import Dotdotdot from 'react-dotdotdot'

const episodesInfo = (number) => {

    let stringNumber = number.toString();

    if(number === 1){
        return 'odcinek'
    }else if(stringNumber[stringNumber.length-1] === '2' || stringNumber[stringNumber.length-1] === '3' || stringNumber[stringNumber.length-1] === '4'){
        return 'odcinki'
    }else {
        return 'odcinków'
    }

};

function Box(props) {

    const {title, episodes, isNew, imageUrl, id} = props;

    return (
        <Link to={"/" + id}>
            <div className='cardDimension'>
                <Card>
                    <Card.Img variant="top" src={imageUrl}/>
                </Card>
                <Card.Title className='boxTitle'>
                    <Dotdotdot clamp={2}>
                    {title}
                    </Dotdotdot>
                </Card.Title>
                <Card.Text>
                    <span className='boxEpisodes'>{episodes} {episodesInfo(episodes)}</span>
                    {isNew === 'true' ? <Badge variant="danger" className='px-2 fs-badge'>Nowy</Badge> : ''}
                </Card.Text>
            </div>
        </Link>
    )
}


export default Box

