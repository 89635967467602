import React, {useEffect, useRef, useState} from 'react';
import {Navbar, Nav, Container} from "react-bootstrap";
import {logo} from "../images";
import {Link} from "react-router-dom";
import {Row, Col} from "react-bootstrap";
import {fetchCategory} from "../api/podcastsAPI";
import TopPlayer from "./topPlayer";
import {connect} from "react-redux";
import ReactAudioPlayer from 'react-audio-player';
import BottomPlayer from "./bottomPlayer";
import {setCategoryName} from "../actions";


const compareCategory = (a, b) => {
    if (a.name_pl < b.name_pl) {
        return -1;
    }
    if (a.name_pl > b.name_pl) {
        return 1;
    }
    return 0;
};


function Header(props) {

    const [tags, setTags] = useState([]);
    const [seekWidth, setSeekWidth] = useState('');
    const [currentTimePodcast, setCurrentTimePodcast] = useState(null);
    const nav = useRef(null);

    let podcastElement;


    useEffect(() => {

        if (props.player.play) {
            podcastElement.audioEl.current.play();
        } else {
            podcastElement.audioEl.current.pause();
        }


    }, [props.player.play]);


    useEffect(() => {

        if (props.player.play) {
            podcastElement.audioEl.current.play();
        } else {
            podcastElement.audioEl.current.pause();
        }


    }, [props.player.id]);


    const getCategory = () => {
        fetchCategory()
            .then(response => {
                if (response) {

                    response.sort(compareCategory);
                    setTags(response)
                }
            })
            .catch(err => console.log(err))
    };

    useEffect(() => {
        getCategory();
    }, []);



    const setCurrentTime = (time) => {

        let min = Math.floor(time / 60);
        let sec = Math.floor(time % 60);

        if(min < 10) min = '0' + min;
        if(sec < 10) sec = '0' + sec;


        return `${min}:${sec}`

    };


    const seekAction = () => {

        let current = podcastElement.audioEl.current.currentTime;


        setSeekWidth((podcastElement.audioEl.current.currentTime / podcastElement.audioEl.current.duration) * 100);

        setCurrentTimePodcast(setCurrentTime(current));


    };


    const setTime = (el) => {

        let newPositionSeek = (el.offsetX / el.target.clientWidth) * Math.floor(podcastElement.audioEl.current.duration);


        if(props.player.play){
            podcastElement.audioEl.current.currentTime = newPositionSeek
        }

    };

    const selectCategory = (item) => {

        nav.current.click();
        window.scrollTo(0, 0);

        if (props.categories.categoryName === item.name_pl) {
            props.setCategory({
                categoryName: ''
            });
        } else {
            props.setCategory({
                categoryName: item.name_pl
            });

        }

    };

    const unselectTag = () => {
        props.setCategory({
            categoryName: ''
        });
    };



    return (
        <>
            <ReactAudioPlayer
                ref={(element) => {
                    podcastElement = element;
                }}
                src={props.player.source}
                onListen={() => seekAction()}
                listenInterval={1000}
            />
            <Navbar variant="dark" expand="md"
                    style={{backgroundColor: '#000', position: 'fixed', width: '100%', zIndex: '99', top: '0'}}>
                <Container fluid style={{maxWidth: '1410px', position: 'relative'}}>
                    <Link to={'/'} className='d-flex align-items-center mr-41' onClick={() => unselectTag()}>
                        <img
                            src={logo}
                            alt="logo"
                            height="32px"
                        />
                        <span className="text-white font-weight-bold text-uppercase ml-3">Podcasty</span>
                    </Link>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" ref={nav}/>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto text-white text-uppercase">
                            <div className="text-white font-weight-bold nav-link d-md-none">Kategorie podcastów</div>
                            <Row className='d-md-none ml-2'>
                                {tags.map((item, index) =>
                                    <Col style={props.categories.categoryName === item.name_pl ? {fontWeight: 'bold'} : null} className='text-ellipsis' xs={6} key={index} onClick={() => selectCategory(item)}>
                                        {item.name_pl}
                                    </Col>)}
                            </Row>
                            <Nav.Link className="text-white mr-41" href="https://radiokielce.pl/" target="_blank">Portal
                                radia kielce</Nav.Link>
                            <Nav.Link className="text-white mr-41" href="https://folkradio.pl/" target="_blank">Folk
                                radio</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                    <TopPlayer {...props}
                               play={props.player.play}
                               title={props.player.title}
                               seek={seekWidth}
                               setTime={setTime}
                               currentTime={currentTimePodcast}/>
                </Container>
            </Navbar>
            <BottomPlayer {...props}
                          play={props.player.play}
                          title={props.player.title}
                          seek={seekWidth}
                          setTime={setTime}
                          currentTime={currentTimePodcast}/>
        </>
    )
}

const mapStateToProps = state => ({
    player: state.player,
    categories: state.category
});

const mapDispatchToProps = dispatch => ({
    setCategory: categories => dispatch(setCategoryName(categories))
});


export default connect(mapStateToProps, mapDispatchToProps)(Header)

