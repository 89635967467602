import axios from 'axios'

const endpoint = 'https://podcasty.radiokielce.pl/api';
// const endpoint = 'https://podcasty-dev.radiokielce.stronazen.pl/api';

const dateCache = new Date().getTime();

export function fetchAll() {
    return axios({
        method: 'get',
        url: `${endpoint}/shows.json?${dateCache}`
    }).then(response => {
            return response.data
        })
        .catch(() => {

        })
}

export function getFeatured() {
    return axios({
        method: 'get',
        url: `${endpoint}/featured.json?${dateCache}`
    }).then(response => {
        return response.data
    })
        .catch(() => {

        })
}

export function fetchCategory() {
    return axios({
        method: 'get',
        url: `${endpoint}/categories.json?${dateCache}`
    }).then(response => {
            return response.data
        })
        .catch(() => {

        })
}


export function getPodcastById(id) {
    return axios({
        method: 'get',
        url: `${endpoint}/show-${id}.json?${dateCache}`
    }).then(response => {
            return response.data
        })
        .catch(() => {

        })
}
